<template>
  <div class="contact">
    <div class="contact-header">
      <h1>Contact me</h1>
    </div>
    <div>
      <Timeline v-for="experience in experiences"
                v-bind:key="experience.id"
                v-bind:left="experience.left"
                v-bind:title="experience.title"
                v-bind:subtitle="experience.subtitle"
                v-bind:year="experience.year"
                v-bind:img="experience.img"
                v-bind:tags="experience.tags"
                v-bind:description="experience.description"
                v-bind:link="experience.link"/>
    </div>
  </div>
</template>

<script>
import Timeline from "@/components/Timeline";

export default {
  name: 'Contact',
  components: {Timeline},
  data: function() {
    let experiences = [
      { id: 1,
        left: true,
        title: 'Mail',
        img: 'mail.png',
        description:
            "<a target='_blank' href='mailto:contact@baptisteboyer.fr' style='color: white'>" +
              "contact@baptisteboyer.fr" +
            "</a>",
        link: 'mailto:contact@baptisteboyer.fr',
      },
      { id: 2,
        left: false,
        title: 'Linkedin',
        img: 'linkedin.png',
        description: "<a target='_blank' href='https://www.linkedin.com/in/baptiste-boyer-74b7b4155/' style='color: white';>" +
            "Follow me on linkedin</a>",
        link: 'https://www.linkedin.com/in/baptisteboyer',
      },
    ];
    return {
      experiences
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.contact-header{
  margin: auto auto auto 10px;
}
@media (min-width: 320px) and (max-width: 480px) {

}
</style>

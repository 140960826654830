<template>
  <div class="competences">
    <div class="competences-header">
      <h1>My hobbies</h1>
    </div>
    <div class="competences-body">
      <SavoirFaire v-for="sf in hobbies"
                   v-bind:key="sf.title"
                   v-bind:title="sf.title"
                   v-bind:img="sf.img"
                   v-bind:note="sf.note"
                   v-bind:description="sf.description"/>
    </div>
  </div>
</template>
<script>
import SavoirFaire from "@/components/SavoirFaire";
export default {
  name: 'Hobbies',
  components: {
    SavoirFaire,
  },
  data: function() {
    let hobbies = [
      { id: 1,
        title: 'Tennis',
        img: 'tennis.jpg',
        description: "In a club for 15 years",
      },
      { id: 2,
        title: 'Football',
        img: 'Football.jpeg',
        description: "I like to watch football and sometimes to play",
      },
      { id: 3,
        title: 'Concerts and music',
        img: 'karaoke.png',
        description: "I love to go to concerts !",
      },
      { id: 4,
        title: 'Drums',
        img: 'drums.jpeg',
        description: "I was in an orchestra for 10 years as a drummer !",
      },
    ];
    return {
      hobbies
    }
  }
}
</script>
<style>
.competences{
  width: 100%;
}
.competences h1{
  margin: auto auto auto 10px;
}
.competences-header{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.competences-body{
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
}
</style>
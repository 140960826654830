<template>
  <div class="parcours">
    <div class="parcours-header">
      <h1>My journey</h1>
      <button class="reverse" v-on:click="experiences.reverse();">Reverse</button>
    </div>
    <Timeline v-for="experience in experiences"
              v-bind:key="experience.id"
              v-bind:left="experience.left"
              v-bind:title="experience.title"
              v-bind:subtitle="experience.subtitle"
              v-bind:year="experience.year"
              v-bind:img="experience.img"
              v-bind:tags="experience.tags"
              v-bind:link="experience.link"
              v-bind:description="experience.description"/>
  </div>
</template>
<script>
import Timeline from "@/components/Timeline";
export default {
  components: {Timeline},
  data: function() {
    let experiences = [
      { id: 1,
        left: false,
        title: 'High School Diploma (Scientific) - Honors',
        subtitle: 'Specialization in Computer Science and Digital Technology',
        year: '2016',
        img: 'bac.png',
        description: "Intrigued by new technologies, I decided to choose the computer science specialization, which allowed me to discover programming (especially Python).",
        tags: ['programming', 'python']
      },
      { id: 2,
        left: true,
        title: 'Summer Camp Counselor',
        subtitle: 'Qualified with a Certification for Vacation Camp Counseling + Worked at summer camps every summer',
        year: '2016 - 2017 - 2018 (summer only)',
        img: 'bafa.jpeg',
        description: "After high school, I decided to obtain a certification to become a vacation camp counselor for children. This allowed me to secure a summer job. I loved this work, which instilled great values in me and helped me develop a sense of responsibility.",
        tags: ['responsibility', 'leadership', 'organization']
      },
      { id: 3,
        left: false,
        title: 'Associate Degree in Computer Science',
        subtitle: 'IUT du Littoral Côte d\'Opale',
        year: '2016 - 2018',
        img: 'iut.png',
        description: "This two years degree allowed me to learn the basics of computer science and confirmed my decision to pursue it as a career! Finishing at the top of my class " +
            '<a target="_blank" href="https://www.facebook.com/permalink.php?story_fbid=2315451285213327&id=888789437879526">(photo)</a>' +
            ", I was then able to apply to engineering schools.",
        tags: ['java', 'php', 'javascript', 'c', 'network']
      },
      { id: 4,
        left: true,
        title: 'Decathlon Digital (internship)',
        subtitle: 'Internship (3 months) - IT HR Department (IAM Team)',
        year: '2018',
        img: 'decathlon.png',
        description: "Full stack web development with Symfony",
        tags: ['iam', 'symfony', 'php', 'html', 'css'],
        link: 'https://recrutement.decathlon.fr/metier/digital-tech/',
      },
      { id: 5,
        left: false,
        title: 'Engineering Degree',
        subtitle: 'Polytech Lille - Computer Science and Statistics Engineering',
        year: '2018 - 2021',
        img: 'polytech.png',
        description: "Engineering education composed of one-third computer science (software engineering, databases, Java, C, ...), one-third statistics (probabilities, numerical algorithms, classification, stochastic processes, R, Python, ...), and one-third humanities, economics, law, and social sciences (data protection and usage, IT and sustainable development, ...).",
        tags: ['java', 'php', 'python', 'r', 'big data', 'machine learning', 'modeling'],
        link: 'https://www.polytech-lille.fr/formation/8-specialites/informatique-et-statistique/'
      },
      { id: 6,
        left: true,
        title: 'Decathlon Digital (work-study)',
        subtitle: 'Work-study - IT HR Department (IAM Team then Development Team)',
        year: '2018 - 2021',
        img: 'decathlon.png',
        description: "<ul>\n" +
            "  <li>Development of an application for the IAM team to empower 2000 Decathlon IT employees in autonomously connecting their applications/services to the identity federation (creation of Oauth2 connections with PingFederate APIs).</li>\n" +
            "  <li>Revamp and modernization of the login page for Decathlon's identity federation (used daily by Decathlon's 100,000 employees).</li>\n" +
            "  <li>Project manager at the end of an apprenticeship, leading a team of 3 developers (1 contractor, 1 apprentice, and 1 intern).</li>\n" +
            "  <li>Proactively suggested the adoption of the Svelte front-end framework within the HR IT teams.</li>\n" +
            "</ul>\n",
        tags: ['javascript', 'angular', 'svelte', 'docker', 'iam', 'php', 'symfony'],
        link: 'https://recrutement.decathlon.fr/metier/digital-tech/',
      },
      { id: 7,
        left: false,
        title: 'Decathlon Digital',
        subtitle: 'Software Development Engineer (Front-end) - IT HR Department',
        year: '2021 - 2023',
        img: 'decathlon.png',
        description: "<ul>\n" +
            "  <li>Lead developer of the jobs, responsibilities, and skills repository (HR data) during the redesign of this application (management of a team of 2 developers with recruitment and operational responsibility for service providers, migration of data to the new version, etc.). Repository used by around twenty applications at Decathlon (training application, payroll, planning, recruitment, etc.).</li>\n" +
            "  <li>Frontend web development (Svelte)</li>\n" +
            "  <li>Artengo brand ambassador - HR IT Department (Digital For People)</li>\n" +
            "</ul>\n",
        tags: ['svelte', 'kafka', 'sql'],
        link: 'https://recrutement.decathlon.fr/metier/digital-tech/',
      },
      { id: 8,
        left: true,
        title: 'Decathlon Digital',
        subtitle: 'Lead Frontend Software Development Engineer - Workspace Department',
        year: '2024',
        img: 'decathlondigital_logo.jpeg',
        description: "<ul>\n" +
            "  <li>Frontend subdomain's point of contact: support for other developers, architecture decisions, convert functional US into technical specifications</li>\n" +
            "  <li>Frontend development: new version from scratch of the Google account management application in collaboration with the Google Workspace suite: service accounts, shared mailboxes, mailing lists (Google groups), etc. (𝗦𝘃𝗲𝗹𝘁𝗲)</li>\n" +
            "  <li>Creation of a 𝗥𝗲𝗮𝗰𝘁 / 𝗩𝗶𝘁𝗲 / 𝗧𝘆𝗽𝗲𝗦𝗰𝗿𝗶𝗽𝘁 template for new applications connected with Decathlon's identity federation (𝗢𝗮𝘂𝘁𝗵𝟮 / Authorization Code PKCE)</li>\n" +
            "  <li>Member of the SIG frontend (special interest group) of Decathlon (24 people)</li>\n" +
            "  <li>Kuikma Tennis Ambassador: brand representative (tests, co-creation, presentation...)</li>\n" +
            "</ul>\n",
        tags: ['react', 'svelte', 'typescript', 'vite'],
        link: 'https://recrutement.decathlon.fr/metier/digital-tech/',
      }
    ];
    experiences = experiences.reverse();
    return {
      experiences
    }
  },
}
</script>
<style>
.parcours{
  width: 100%;
}
.parcours h1{
  margin: auto auto auto 10px;
}
.parcours-header{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.reverse{
  background-color: rgba(192,192,192, 0.5);
  padding: 10px;
  border-radius: 50px;
  border: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  cursor: pointer;
  margin: auto 0px auto auto;
}
ul{
  text-align: left;
  margin: 0;
}
</style>
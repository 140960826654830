<template>
  <div class="competences">
    <div class="competences-header">
      <h1>My main skills</h1>
    </div>
    <div class="competences-body">
      <SavoirFaire v-for="sf in savoirFairesPrincipaux"
                v-bind:key="sf.title"
                v-bind:title="sf.title"
                v-bind:img="sf.img"
                v-bind:note="sf.note"
                v-bind:description="sf.description"/>
    </div>
    <div class="competences-header">
      <h1>My other skills</h1>
    </div>
    <div class="competences-body">
      <SavoirFaire v-for="sf in savoirFairesSecondaires"
                   v-bind:key="sf.title"
                   v-bind:title="sf.title"
                   v-bind:img="sf.img"
                   v-bind:note="sf.note"
                   v-bind:description="sf.description"/>
    </div>
  </div>
</template>
<script>
import SavoirFaire from "@/components/SavoirFaire";
export default {
  name: 'Competences',
  components: {
    SavoirFaire,
  },
  data: function() {
    let savoirFairesPrincipaux = [
      { id: 1,
        title: 'Front-end Development - Javascript',
        img: 'JavaScript-logo.png',
        description: "Lead developer for Svelte and React projects (SPA). I also like to do design in CSS.",
        note: 4,
      },
      { id: 2,
        title: 'Continuous Integration',
        img: 'jenkins.png',
        description: "Completed training on Jenkins and created JenkinsFiles using an existing library.",
        note: 3,
      },
      { id: 3,
        title: 'Containerization',
        img: 'docker.png',
        description: "Completed training on Docker and created several DockerFiles and DockerCompose files.",
        note: 3,
      },
      { id: 4,
        title: 'Authentication',
        img: 'ping.png',
        description: "Thanks to my experience in IAM Team, I've learned many things about Oauth2 authentication.",
        note: 3,
      },
    ];

    let savoirFairesSecondaires = [
      { id: 1,
        title: 'GitHub',
        img: 'github.jpeg',
        description: "Proficient in GitHub and its features (PR, Issues, Project...).",
        note: 4,
      },
      { id: 2,
        title: 'Jira',
        img: 'jira.png',
        description: "Used Jira in the context of agile methodologies.",
        note: 3,
      },
      { id: 3,
        title: 'Back-end Development - PHP',
        img: 'php.jpeg',
        description: "Completed a full project on Symfony 4, explored API Platform.",
        note: 2,
      },
      { id: 4,
        title: 'Back-end Development - Java',
        img: 'java.png',
        description: "Completed school projects (checkers game...) and made some fixes in a company setting.",
        note: 2,
      },
      { id: 5,
        title: 'English',
        img: 'anglais.png',
        description: "TOEIC score: 920 (including 490/495 in listening).",
        note: 4,
      },
      { id: 6,
        title: 'Data Science',
        img: 'ml.png',
        description: "Completed school projects in machine learning (regressions, decision trees, neural networks...).",
        note: 2,
      },
    ];
    return {
      savoirFairesPrincipaux, savoirFairesSecondaires
    }
  }
}
</script>
<style>
.competences{
  width: 100%;
}
.competences h1{
  margin: auto auto auto 10px;
}
.competences-header{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.competences-body{
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
}
</style>
<template>
  <div class="sf">
    <img class="sf-photo" :src="getImgUrl(img)" :alt="title">
    <div class="sf-content">
      <div class="sf-title">
        <span class="sf-title-intitule">{{title}}</span>
        <div v-if="note !== undefined" class="sf-title-notation">
          <div v-for="index in note" :key="index" class="sf-note sf-note-pleine"></div>
          <div v-for="index in (5-note)" :key="index + 5" class="sf-note sf-note-vide"></div>
        </div>
      </div>
      <div class="sf-description">{{description}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sf',
  props: ['title', 'description', 'img', 'note', 'id'],
  methods:{
    getImgUrl(pic) {
      return require('../assets/'+pic)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.sf{
  padding: 1% 2%;
  background-color: rgba(255,252,249, 0.5);
  border-radius: 30px;
  display: flex;
  margin: 4vh auto;
  color: #2c3e50;
  width: 40%;
}
a{
  color: lightblue;
  text-decoration: none;
}
.sf-photo{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid white;
  margin: auto 10px auto 10px;
  background-color: white;
}
.sf-content{
  margin: auto auto auto 0px;
}
.sf-title{
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
}
.sf-title-intitule{
  margin-right: 3px;
}
.sf-title-notation{
  display: flex;
}

.sf-note{
  margin: auto 1px auto 2px;
  border-radius: 50%;
  display: inline-block;
}

.sf-note-pleine{
  width: 6px;
  height: 6px;
  background-color: #2c3e50;
}
.sf-note-vide{
  width: 4px;
  height: 4px;
  border: 1px solid #2c3e50;
}

@media (min-width: 320px) and (max-width: 480px) {
  .sf{
    margin: 1vh auto;
  }
  .sf{
    padding: 15px;
    width: 80%;
  }
}

</style>

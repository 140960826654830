<template>
  <div id="menu">
    <router-link class="menu-item" to="/">Who am I ?</router-link><span class="menu-pipe"> | </span>
    <router-link class="menu-item" to="/journey">Journey</router-link><span class="menu-pipe"> | </span>
    <router-link class="menu-item" to="/skills">Skills</router-link><span class="menu-pipe"> | </span>
    <router-link class="menu-item" to="/hobbies">Hobbies</router-link><span class="menu-pipe"> | </span>
    <router-link class="menu-item" to="/contact">Contact</router-link>
  </div>
</template>

<script>
export default {
  name: 'Menu',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#menu{
  background-color:rgba(192,192,192, 0.5);
  padding: 10px;
  border-radius: 50px;
  margin: auto;
  text-align: center;
  /*position: absolute;
  left: 50%;
  top: 2%;
  transform: translateX(-50%);*/
}

#menu a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

#menu a.router-link-exact-active {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}


</style>

<template>
  <div>
      <div class="timeline" v-bind:class="{ left: left }">
        <a v-bind:href="link" target='_blank' >
          <img v-if="!left" class="timeline-photo" :src="getImgUrl(img)">
        </a>
        <div class="timeline-experience">
          <div class="timeline-experience-year">{{year}}</div>
          <div class="timeline-experience-title">{{title}}</div>
          <div class="timeline-experience-subtitle">{{subtitle}}</div>
          <div class="timeline-experience-description" v-html="description"></div>
        </div>
        <a v-bind:href="link" target='_blank'>
          <img v-if="left" class="timeline-photo" :src="getImgUrl(img)" :alt="title">
        </a>
      </div>
      <div class="timeline-tags" v-bind:class="{ leftTimeline: left }">
        <div class="tag" v-for="tag in tags" :key="tag.id">{{tag}}</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  props: ['left', 'title', 'subtitle', 'year', 'img', 'tags', 'description', 'link'],
  methods:{
    getImgUrl(pic) {
      return require('../assets/'+pic)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.timeline{
  width: 46%;
  margin: 4vh 0px 0px auto;
  border-left: 1px solid white;
  padding: 0px 2%;
  display: flex;
}
.timeline-tags{
  width: 46%;
  margin: 1vh 0px 0px auto;
  padding: 0px 2%;
}
.leftTimeline{
  margin: 1vh auto 0px 0px;
  text-align: right;
}
.left{
  margin: 4vh auto 0px 0px;
  text-align: right;
  border-right: 1px solid white;
  border-left: 0px solid white;
}
.timeline-experience{
  max-width: 75%;
  margin: auto auto auto 2vw;
}
.left .timeline-experience{
  max-width: 75%;
  margin: auto 2vw auto auto;
}
.timeline-experience-title{
  font-weight: bold;
  font-size: 200%;
}

.timeline-experience-subtitle{
  color: lightyellow;
  font-style: italic;
}
.timeline-experience-year{
  font-weight: bold;
}
.timeline-photo{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid white;
  margin: auto 0px auto 0px;
}
a{
  color: white;
  text-decoration: none;
}

.tag{
  background-color: rgba(255,252,249, 0.5);
  padding: 5px 10px;
  border-radius: 50px;
  border: 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .timeline, .left, .timeline-tags, .leftTimeline{
    margin: 1vh auto;
  }
  .timeline{
    padding: 15px;
    width: 85%;
    text-align: right;
  }
  .timeline-tags{
    width: 90%;
  }
  .left{
    text-align: left;
  }
  .timeline-experience-title{
    font-weight: bold;
    font-size: 150%;
  }

  .timeline-experience{
    max-width: 100%;
  }
}

</style>
